import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './style.module.sass'

import Hover from '../../utils/hover'

const MediaPageComponent = ({ _dataMedia }) => {
    return (
        <div className={styles.media}>
            {_dataMedia.mediaList.length !== 0 && (
                <ol>
                    {_dataMedia.mediaList.map((mediaItem, index) => (
                        <li
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${mediaItem.date}${index}`}
                            className={styles.media__item}
                        >
                            <p className={styles.media__item__pre_date}>20</p>
                            <p className={styles.media__item__date}>{mediaItem.date}</p>
                            <p className={styles.media__item__category}>{mediaItem.category}</p>
                            <p className={styles.media__item__title_wrap}>
                                {mediaItem.url !== null ? (
                                    <Hover hoverClass={styles.media__item__title_hovered}>
                                        <a
                                            href={mediaItem.url}
                                            className={styles.media__item__title}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {mediaItem.title}
                                        </a>
                                    </Hover>
                                ) : (
                                    <span
                                        className={`${styles.media__item__title} ${styles.media__item__title_no_link}`}
                                    >
                                        {mediaItem.title}
                                    </span>
                                )}
                            </p>
                        </li>
                    ))}
                </ol>
            )}
        </div>
    )
}

export default MediaPageComponent

MediaPageComponent.propTypes = {
    _dataMedia: PropTypes.shape({
        mediaList: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.string,
                category: PropTypes.string,
                title: PropTypes.string,
                url: PropTypes.string,
            })
        ),
    }).isRequired,
}

import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Seo from '../components/utils/seo'

import MediaPageComponent from '../components/page/mediaPageComponent'

const MediaPage: React.FC<PageProps<GatsbyTypes.MediaPageQuery>> = ({ data }) => {
    return <MediaPageComponent _dataMedia={data.allWpPage.edges[0].node.media} />
}

export default MediaPage

export const Head = () => <Seo _title="Media" />

export const pageQuery = graphql`
    query MediaPage {
        allWpPage(filter: { slug: { eq: "media" } }) {
            edges {
                node {
                    media {
                        mediaList {
                            date
                            category
                            title
                            url
                        }
                    }
                }
            }
        }
    }
`
